<template>
  <v-menu 
    v-model="view.toggle"
    :close-on-content-click="false"
    offset-y
    max-width="360"
  >
    <template v-slot:activator="menu">
      <v-tooltip bottom>
        <template v-slot:activator="tooltip">
          <v-btn
            v-show="!isEmpty"
            fab
            icon
            small
            :loading="loading"
            :color="hasUnread ? 'primary' : 'grey lighten-1'"
            class="notifications-btn mx-1"
            v-bind="{ ...menu.attrs, ...tooltip.attrs }"
            v-on="{ ...menu.on, ...tooltip.on }"
          >
            <v-icon dense>
              {{ hasUnread ? icons.new : icons.bell }}
            </v-icon>
          </v-btn>
        </template>
        Notificações
      </v-tooltip>
    </template>
    <v-card 
      min-width="320"
      class="notifications-list"
    >
      <v-subheader class="text-overline grey--text">
        Notificações
      </v-subheader>
      <v-divider />
      <v-list 
        max-height="50vh"
        max-width="320"
        class="scrollable"
      >
        <v-list-item
          v-for="item in list"
          :key="'notification-'+item.id"
          :class="[{ 'unread': item.read==null }, (item.read==null ? 'primary' : 'grey')+'--text']"
          class="notification"
          @click="open(item)"
        >
          <v-list-item-content>
            <v-list-item-title 
              :class="[(item.read==null ? 'primary--text' : 'grey--text text--darken-1')]"
              class="text-subtitle-2 pb-1"
            >
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-body-2 text-untruncate grey--text">
              {{ item.text }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<style>

  .notification.unread {
    border-left: 3px solid;
  }
  
</style>

<script>
  import { 
    mdiBell,
    mdiBellBadge,
  } from '@mdi/js';
  import services from '@/services'
  import { sync } from 'vuex-pathify'
  import { get as $get, read as $read } from '@/api/notifications'
  const moment = require('moment');

  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      user: {
        type: Object,
        default: () => {}
      }
    },

    data: () => ({
      icons: {
        bell: mdiBell,
        new: mdiBellBadge,
      },
      view: {
        toggle: false,
        timer: null,
        interval: 300000,
      },
      loading: false,
      updated: false,
    }),

    computed: {
      notifications: sync('user/notifications'),

      list () {
        const list = this.notifications.data;
        return _.orderBy(_.values(list), ['sent'], ['desc'])
      },

      hasUnread () {
        return _.some(this.list, ['read', null]);
      },

      isEmpty () {
        return _.size(this.list) == 0;
      },
    },

    watch: {
      visible (b) {
        const updatedAt = this.notifications.updatedAt;
        if (b&&(_.isNil(updatedAt)||moment().diff(updatedAt, 'ms')>this.view.interval)) {
          if (!_.isNil(this.view.timer)) clearTimeout(this.view.timer);
          this.get()
        }
      },
      'view.toggle' (b) {
        if (!b&&this.hasUnread) {
          this.read()
        }
      }
    },

    methods: {
      ...services,
      $get,
      $read,

      get () {
        this.loading = true;
        this.updated = false;
        this.$get(
          this.user.auth.token,
          (data) => {
            this.notifications.data = Object.assign({}, _.keyBy(data, 'id'));
            this.notifications.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
            this.updated = true;
          },
          (error) => { 
            // TODO
          },
          () => { 
            this.loading = false;
            this.view.timer = setTimeout(($) => {
              this.job()
            }, this.view.interval, this);
          }
        )
      },
      read () {
        this.loading = true;
        this.updated = false;
        const notifications = _.map(_.filter(this.list, ['read', null]), 'id')
        this.$read(
          this.user.auth.token,
          notifications,
          (data) => {
            _.each(notifications, n => {
              const data = this.notifications.data[n];
              this.notifications.data[n] = Object.assign(data, { ...data, read: moment().valueOf() });
            })
            this.updated = true;
          },
          (error) => { 
            // TODO
          },
          () => { 
            this.loading = false;
          }
        )
      },
      open (notification) {
        this.read();
      },
      job () {
        if (this.visible&&!this.loading) {
          if (!_.isNil(this.view.timer)) clearTimeout(this.view.timer);
          this.get()
        }
      }
    },

    mounted () {
      // this.get()
    },
    beforeDestroy () {
      if (_.isNil(this.view.timer)) clearTimeout(this.view.timer);
    }
  }
</script>