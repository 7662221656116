import _ from 'lodash';

function get (token, resolve, reject, complete) {
  console.log('notifications.get ...');
  
  this.$api.SSP.get('/notifications/', {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(response => {
    console.log('notifications.get => ', response);
    const data = _.map(response.data, n => {
      return {
        id: n.id_notification,
        title: n.content.title,
        text: n.content.text,
        read: n.dt_read,
        sent: n.dt_sent,
        reference: n.reference
      }
    })
    resolve(data);
  })
  .catch(error => {
    this.handleError(error);
    if (!_.isNil(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (!_.isNil(complete)) complete();
  });
}

function read (token, notifications, resolve, reject, complete) {
  console.log('notifications.read ...');
  
  this.$api.SSP.post('/notifications/', new URLSearchParams({ notifications }), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    console.log('notifications.read => ', response);
    resolve(response.data);
  })
  .catch(error => {
    this.handleError(error);
    if (!_.isNil(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (!_.isNil(complete)) complete();
  });
}

export {
  get,
  read
}
